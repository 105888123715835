import * as React from "react";
import styled from "styled-components";
import ChromeLogo from "../../assets/chromeLogo";
import EdgeLogo from "../../assets/edgeLogo";
import FirefoxLogo from "../../assets/firefoxLogo";
import { H2, H3 } from "../atoms/headings";

// import  Chrome from "../../assets/chrome-logo.svg";

// import Chrome from "../../assets/chrome-logo.svg";

const Wrapper = styled.div`
  max-width: 1280px;
  /* width: 100%; */
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Card = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  padding: 3rem;
  border-radius: 7px;
  transition: 0.5s;
  :hover {
    transform: scale(1.035);
    box-shadow: 1px 2px 4px grey;
  }
`;

interface ExtensionsProps {}

const Extensions: React.FunctionComponent<ExtensionsProps> = () => {
  return (
    <Wrapper>
      <H2 style={{ textAlign: "center", width: "100%" }}>
        Get the CiteDrive Companion plugin
      </H2>
      <H3 style={{ marginBottom: "2.5rem", padding: "1rem" }}>
        Instantly start collecting references and inserting citations into your
        favorite editor with your free account
      </H3>
      <Container>
        <Card href="https://chrome.google.com/webstore/detail/citedrive-bibtex-companio/gmmonfphegngpcbcapfbgembkjeookik">
          <ChromeLogo />
          <H3>Google Chrome</H3>
        </Card>
        <Card href="https://addons.mozilla.org/af/firefox/addon/citedrive-companion/">
          {" "}
          <FirefoxLogo /> <H3>Firefox</H3>
        </Card>
        {/* <Card href="https://microsoftedge.microsoft.com/addons/detail/citedrive-bibtex-compan/kpmepfpbjehnlgfbcnbmgihklkkmfnpo">
          {" "}
          <EdgeLogo /> <H3>Edge</H3>
        </Card> */}
      </Container>
    </Wrapper>
  );
};

export default Extensions;
