import * as React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import Hero from "../components/index/hero";
import Features from "../components/index/features";

import homepageSchema from "../schema.org/homepageSchema.json";
import CallToActionPanel from "../components/overleaf/callToActionPanel";
import Extensions from "../components/index/extensions";
import { LargeP } from "../components/atoms/paragraph";

// Sofware Application

const Container = styled.div`
  margin-top: var(--top-margin);
  display: flex;
  flex-direction: column;
  gap: 15rem;
`;

// markup
const IndexPage = ({ data }) => {
  if (!data) return null;

  // saved responses

  // meta
  const doc = data.prismicHomepage;
  const seoTitle = doc.data.meta.map((ele) => ele.primary.seo_title);
  const description = doc.data.meta.map((ele) => ele.primary.meta_description);

  // hero
  const language = doc.lang;
  const heroTitle = doc.data.hero_title.text;
  const heroSubTitle = doc.data.hero_subtitle.text;

  // features
  const bodyText = doc.data.body_text.text;
  const accordionData = doc.data.features;

  const { lang, type, url } = doc || {};
  const alternateLanguages = doc.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  // schema.org schema :)

  // software app

  // Homepage bzw. Overleaf Landingpage: Software App oder Corporation
  // FAQ check
  // Feature: Breadcrumps schema
  // Anleitung

  const schema = homepageSchema;

  // const schema = {
  //   "@context": "https://schema.org",
  //   "@type": "Corporation",
  //   name: "CiteDrive LLC",
  //   url: "https://www.citedrive.com",
  //   logo: "",
  //   sameAs: ["https://twitter.com/CiteDrive", "https://citedrive.com"],
  // };

  return (
    <Layout activeDocMeta={activeDoc}>
      <SEO
        title={seoTitle}
        lang={language}
        description={description}
        type="webpage"
        schemaHomepage={schema}
      />
      <Container>
        <Hero title={heroTitle} subtitle={heroSubTitle} />

        <div style={{
          margin: "0 auto",
          padding: "0 1rem"
        }}>
        <LargeP style={{fontWeight: "600", textAlign: "center"}}>{bodyText}</LargeP>
        </div>

        <Features accordionData={accordionData} />

        <Extensions />
        <CallToActionPanel
        heading="Stop doing references by hand"
        subheading="Create your account - it's free"
        label="Create my free account"
         />
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Homepage($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        meta {
          ... on PrismicHomepageDataMetaMetaTags {
            primary {
              seo_title
              meta_description
            }
          }
        }
        features {
          ... on PrismicHomepageDataFeaturesFeatureAccordion {
            id
            items {
              feature_name {
                richText
                text
              }
              feature_text {
                richText
                text
              }
            }
          }
        }
        hero_title {
          richText
          text
        }
        hero_subtitle {
          richText
          text
        }
        call_to_action_button {
          label
        }
        body_text {
          text
          richText
        }
      }
    }
  }
`;
