import * as React from "react"

const FirefoxLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // height={800}
    width={200}
    viewBox="-11.613 -19.992 100.644 119.951"
    {...props}
  >
    <defs>
      <radialGradient
        id="b"
        cx={-7907.187}
        cy={-8515.121}
        r={80.797}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.129} stopColor="#ffbd4f" />
        <stop offset={0.186} stopColor="#ffac31" />
        <stop offset={0.247} stopColor="#ff9d17" />
        <stop offset={0.283} stopColor="#ff980e" />
        <stop offset={0.403} stopColor="#ff563b" />
        <stop offset={0.467} stopColor="#ff3750" />
        <stop offset={0.71} stopColor="#f5156c" />
        <stop offset={0.782} stopColor="#eb0878" />
        <stop offset={0.86} stopColor="#e50080" />
      </radialGradient>
      <radialGradient
        id="c"
        cx={-7936.711}
        cy={-8482.089}
        r={80.797}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.3} stopColor="#960e18" />
        <stop offset={0.351} stopColor="#b11927" stopOpacity={0.74} />
        <stop offset={0.435} stopColor="#db293d" stopOpacity={0.343} />
        <stop offset={0.497} stopColor="#f5334b" stopOpacity={0.094} />
        <stop offset={0.53} stopColor="#ff3750" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="d"
        cx={-7926.97}
        cy={-8533.457}
        r={58.534}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.132} stopColor="#fff44f" />
        <stop offset={0.252} stopColor="#ffdc3e" />
        <stop offset={0.506} stopColor="#ff9d12" />
        <stop offset={0.526} stopColor="#ff980e" />
      </radialGradient>
      <radialGradient
        id="e"
        cx={-7945.648}
        cy={-8460.984}
        r={38.471}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.353} stopColor="#3a8ee6" />
        <stop offset={0.472} stopColor="#5c79f0" />
        <stop offset={0.669} stopColor="#9059ff" />
        <stop offset={1} stopColor="#c139e6" />
      </radialGradient>
      <radialGradient
        id="f"
        cx={-7935.62}
        cy={-8491.546}
        r={20.397}
        gradientTransform="matrix(.972 -.235 .275 1.138 10090.002 7833.794)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.206} stopColor="#9059ff" stopOpacity={0} />
        <stop offset={0.278} stopColor="#8c4ff3" stopOpacity={0.064} />
        <stop offset={0.747} stopColor="#7716a8" stopOpacity={0.45} />
        <stop offset={0.975} stopColor="#6e008b" stopOpacity={0.6} />
      </radialGradient>
      <radialGradient
        id="g"
        cx={-7937.731}
        cy={-8518.427}
        r={27.676}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ffe226" />
        <stop offset={0.121} stopColor="#ffdb27" />
        <stop offset={0.295} stopColor="#ffc82a" />
        <stop offset={0.502} stopColor="#ffa930" />
        <stop offset={0.732} stopColor="#ff7e37" />
        <stop offset={0.792} stopColor="#ff7139" />
      </radialGradient>
      <radialGradient
        id="h"
        cx={-7915.977}
        cy={-8535.981}
        r={118.081}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.113} stopColor="#fff44f" />
        <stop offset={0.456} stopColor="#ff980e" />
        <stop offset={0.622} stopColor="#ff5634" />
        <stop offset={0.716} stopColor="#ff3647" />
        <stop offset={0.904} stopColor="#e31587" />
      </radialGradient>
      <radialGradient
        id="i"
        cx={-7927.165}
        cy={-8522.859}
        r={86.499}
        gradientTransform="matrix(.105 .995 -.653 .069 -4685.304 8470.187)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff44f" />
        <stop offset={0.06} stopColor="#ffe847" />
        <stop offset={0.168} stopColor="#ffc830" />
        <stop offset={0.304} stopColor="#ff980e" />
        <stop offset={0.356} stopColor="#ff8b16" />
        <stop offset={0.455} stopColor="#ff672a" />
        <stop offset={0.57} stopColor="#ff3647" />
        <stop offset={0.737} stopColor="#e31587" />
      </radialGradient>
      <radialGradient
        id="j"
        cx={-7938.383}
        cy={-8508.176}
        r={73.72}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.137} stopColor="#fff44f" />
        <stop offset={0.48} stopColor="#ff980e" />
        <stop offset={0.592} stopColor="#ff5634" />
        <stop offset={0.655} stopColor="#ff3647" />
        <stop offset={0.904} stopColor="#e31587" />
      </radialGradient>
      <radialGradient
        id="k"
        cx={-7918.923}
        cy={-8503.861}
        r={80.686}
        gradientTransform="translate(7973.7 8523.996)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.094} stopColor="#fff44f" />
        <stop offset={0.231} stopColor="#ffe141" />
        <stop offset={0.509} stopColor="#ffaf1e" />
        <stop offset={0.626} stopColor="#ff980e" />
      </radialGradient>
      <linearGradient
        id="a"
        x1={70.786}
        y1={12.393}
        x2={6.447}
        y2={74.468}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-1.3 -.004)"
      >
        <stop offset={0.048} stopColor="#fff44f" />
        <stop offset={0.111} stopColor="#ffe847" />
        <stop offset={0.225} stopColor="#ffc830" />
        <stop offset={0.368} stopColor="#ff980e" />
        <stop offset={0.401} stopColor="#ff8b16" />
        <stop offset={0.462} stopColor="#ff672a" />
        <stop offset={0.534} stopColor="#ff3647" />
        <stop offset={0.705} stopColor="#e31587" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={70.013}
        y1={12.061}
        x2={15.267}
        y2={66.806}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-1.3 -.004)"
      >
        <stop offset={0.167} stopColor="#fff44f" stopOpacity={0.8} />
        <stop offset={0.266} stopColor="#fff44f" stopOpacity={0.634} />
        <stop offset={0.489} stopColor="#fff44f" stopOpacity={0.217} />
        <stop offset={0.6} stopColor="#fff44f" stopOpacity={0} />
      </linearGradient>
    </defs>
    <path
      d="M74.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764l.007.065C66.391 17.921 58.96 13.516 52.891 3.924a47.099 47.099 0 0 1-.913-1.484 12.24 12.24 0 0 1-.427-.8 7.053 7.053 0 0 1-.578-1.535.1.1 0 0 0-.088-.1.138.138 0 0 0-.073 0c-.005 0-.013.009-.019.011s-.019.011-.028.015l.015-.026c-9.735 5.7-13.038 16.252-13.342 21.53a19.387 19.387 0 0 0-10.666 4.111 11.587 11.587 0 0 0-1-.758 17.968 17.968 0 0 1-.109-9.473 28.705 28.705 0 0 0-9.329 7.21h-.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294.687 28.225 28.225 0 0 0-3.788 3.245 33.845 33.845 0 0 0-3.623 4.347v.006-.007a32.733 32.733 0 0 0-5.2 11.743l-.052.256a61.89 61.89 0 0 0-.381 2.42c0 .029-.006.056-.009.085A36.937 36.937 0 0 0 0 41.042v.2a38.759 38.759 0 0 0 76.954 6.554c.065-.5.118-.995.176-1.5a39.857 39.857 0 0 0-2.514-19.469zm-44.67 30.338c.181.087.351.181.537.264l.027.017q-.282-.135-.564-.281zm8.878-23.376zm31.952-4.934v-.037l.007.041z"
      fill="url(#a)"
    />
    <path
      d="M74.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764v.037l.007.041a35.1 35.1 0 0 1-1.206 26.158c-4.442 9.531-15.194 19.3-32.024 18.825-18.185-.515-34.2-14.009-37.194-31.683-.545-2.787 0-4.2.274-6.465A28.876 28.876 0 0 0 0 41.042v.2a38.759 38.759 0 0 0 76.954 6.554c.065-.5.118-.995.176-1.5a39.857 39.857 0 0 0-2.514-19.469z"
      fill="url(#b)"
    />
    <path
      d="M74.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764v.037l.007.041a35.1 35.1 0 0 1-1.206 26.158c-4.442 9.531-15.194 19.3-32.024 18.825-18.185-.515-34.2-14.009-37.194-31.683-.545-2.787 0-4.2.274-6.465A28.876 28.876 0 0 0 0 41.042v.2a38.759 38.759 0 0 0 76.954 6.554c.065-.5.118-.995.176-1.5a39.857 39.857 0 0 0-2.514-19.469z"
      fill="url(#c)"
    />
    <path
      d="M55.782 31.383c.084.059.162.118.241.177a21.1 21.1 0 0 0-3.6-4.695C40.377 14.817 49.266.742 50.765.027l.015-.022c-9.735 5.7-13.038 16.252-13.342 21.53.452-.031.9-.069 1.362-.069a19.56 19.56 0 0 1 16.982 9.917z"
      fill="url(#d)"
    />
    <path
      d="M38.825 33.789c-.064.964-3.47 4.289-4.661 4.289-11.021 0-12.81 6.667-12.81 6.667.488 5.614 4.4 10.238 9.129 12.684.216.112.435.213.654.312q.569.252 1.138.466a17.235 17.235 0 0 0 5.043.973c19.317.906 23.059-23.1 9.119-30.066a13.38 13.38 0 0 1 9.345 2.269A19.56 19.56 0 0 0 38.8 21.466c-.46 0-.91.038-1.362.069a19.387 19.387 0 0 0-10.666 4.111c.591.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
      fill="url(#e)"
    />
    <path
      d="M38.825 33.789c-.064.964-3.47 4.289-4.661 4.289-11.021 0-12.81 6.667-12.81 6.667.488 5.614 4.4 10.238 9.129 12.684.216.112.435.213.654.312q.569.252 1.138.466a17.235 17.235 0 0 0 5.043.973c19.317.906 23.059-23.1 9.119-30.066a13.38 13.38 0 0 1 9.345 2.269A19.56 19.56 0 0 0 38.8 21.466c-.46 0-.91.038-1.362.069a19.387 19.387 0 0 0-10.666 4.111c.591.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
      fill="url(#f)"
    />
    <path
      d="M24.965 24.357c.314.2.573.374.8.531a17.968 17.968 0 0 1-.109-9.473 28.705 28.705 0 0 0-9.329 7.21c.189-.005 5.811-.106 8.638 1.732z"
      fill="url(#g)"
    />
    <path
      d="M.354 42.159c2.991 17.674 19.009 31.168 37.194 31.683 16.83.476 27.582-9.294 32.024-18.825a35.1 35.1 0 0 0 1.206-26.158v-.037c0-.029-.006-.046 0-.037l.007.065c1.375 8.977-3.191 17.674-10.329 23.555l-.022.05c-13.908 11.327-27.218 6.834-29.912 5q-.282-.135-.564-.281c-8.109-3.876-11.459-11.264-10.741-17.6a9.953 9.953 0 0 1-9.181-5.775 14.618 14.618 0 0 1 14.249-.572 19.3 19.3 0 0 0 14.552.572c-.015-.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.587 11.587 0 0 0-1-.758c-.23-.157-.489-.327-.8-.531-2.827-1.838-8.449-1.737-8.635-1.732h-.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294.687 28.225 28.225 0 0 0-3.788 3.245 33.845 33.845 0 0 0-3.638 4.337v.006-.007a32.733 32.733 0 0 0-5.2 11.743c-.019.079-1.396 6.099-.717 9.221z"
      fill="url(#h)"
    />
    <path
      d="M52.425 26.865a21.1 21.1 0 0 1 3.6 4.7c.213.161.412.321.581.476 8.787 8.1 4.183 19.55 3.84 20.365 7.138-5.881 11.7-14.578 10.329-23.555-4.384-10.93-11.815-15.335-17.884-24.927a47.099 47.099 0 0 1-.913-1.484 12.24 12.24 0 0 1-.427-.8 7.053 7.053 0 0 1-.578-1.535.1.1 0 0 0-.088-.1.138.138 0 0 0-.073 0c-.005 0-.013.009-.019.011s-.019.011-.028.015c-1.499.711-10.388 14.786 1.66 26.834z"
      fill="url(#i)"
    />
    <path
      d="M56.6 32.036a8.083 8.083 0 0 0-.581-.476c-.079-.059-.157-.118-.241-.177a13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.972-9.119 30.066a17.235 17.235 0 0 1-5.043-.973q-.569-.213-1.138-.466c-.219-.1-.438-.2-.654-.312l.027.017c2.694 1.839 16 6.332 29.912-5l.022-.05c.347-.81 4.951-12.263-3.84-20.36z"
      fill="url(#j)"
    />
    <path
      d="M21.354 44.745s1.789-6.667 12.81-6.667c1.191 0 4.6-3.325 4.661-4.289a19.3 19.3 0 0 1-14.552-.572 14.618 14.618 0 0 0-14.249.572 9.953 9.953 0 0 0 9.181 5.775c-.718 6.337 2.632 13.725 10.741 17.6.181.087.351.181.537.264-4.733-2.445-8.641-7.069-9.129-12.683z"
      fill="url(#k)"
    />
    <path
      d="M74.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764l.007.065C66.391 17.921 58.96 13.516 52.891 3.924a47.099 47.099 0 0 1-.913-1.484 12.24 12.24 0 0 1-.427-.8 7.053 7.053 0 0 1-.578-1.535.1.1 0 0 0-.088-.1.138.138 0 0 0-.073 0c-.005 0-.013.009-.019.011s-.019.011-.028.015l.015-.026c-9.735 5.7-13.038 16.252-13.342 21.53.452-.031.9-.069 1.362-.069a19.56 19.56 0 0 1 16.982 9.917 13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.972-9.119 30.066a17.235 17.235 0 0 1-5.043-.973q-.569-.213-1.138-.466c-.219-.1-.438-.2-.654-.312l.027.017q-.282-.135-.564-.281c.181.087.351.181.537.264-4.733-2.446-8.641-7.07-9.129-12.684 0 0 1.789-6.667 12.81-6.667 1.191 0 4.6-3.325 4.661-4.289-.015-.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.587 11.587 0 0 0-1-.758 17.968 17.968 0 0 1-.109-9.473 28.705 28.705 0 0 0-9.329 7.21h-.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294.687 28.225 28.225 0 0 0-3.788 3.245 33.845 33.845 0 0 0-3.623 4.347v.006-.007a32.733 32.733 0 0 0-5.2 11.743l-.052.256c-.073.341-.4 2.073-.447 2.445 0 .028 0-.029 0 0A45.094 45.094 0 0 0 0 41.042v.2a38.759 38.759 0 0 0 76.954 6.554c.065-.5.118-.995.176-1.5a39.857 39.857 0 0 0-2.514-19.469zm-3.845 1.991.007.041z"
      fill="url(#l)"
    />
  </svg>
)

export default FirefoxLogo
