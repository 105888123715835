import * as React from "react";
import styled from "styled-components";
import { HeroSubtitle } from "../atoms/paragraph";
import Testgrafik from "../../assets/test-grafik.png";
import { CallToActionButtonA } from "../atoms/button";
import CallToActionPanel from "../overleaf/callToActionPanel";

// styles

const Wrapper = styled.section`
  
`;

const HeroContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

// const Item = styled.div<ItemType>`
//   padding: 1rem;  
//   flex-basis: min(calc(640px - 2.5rem), 100%);
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   @media (min-width: 680px) {
//     padding: 0rem;
//   }
// `;

type HeroItemType = {
  flex: string;
};

const VideoWrapper = styled.div<HeroItemType>`
  flex: 100%;
  @media (min-width: 1028px) {
    flex: ${(props) => props.flex};
  }
`;

const TextWrapper = styled.div<HeroItemType>`
  padding: 1rem;
  position: relative;
  flex: 100%;
  @media (min-width: 1028px) {
    flex: ${(props) => props.flex};
    padding: 0;
  }
`;

const TextContainer = styled.div`
  margin-top: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1028px) {
    padding: 2rem;
  }
`;




const HeroTitle = styled.h1`
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: clamp(1.2rem, 7vmin, 2.5rem);
  margin-bottom: 1rem;
  letter-spacing: 0.8px;
  color: #1b1b1b;
`;

interface NewHeroProps {
  title: string;
  subtitle: string;
}

const Hero: React.FunctionComponent<NewHeroProps> = ({ title, subtitle }) => {
  return (
    <Wrapper>
      <HeroContainer>

        <VideoWrapper flex="calc(50% - 2rem)">
        
          <img src={Testgrafik} alt="test" />

        </VideoWrapper>

        <TextWrapper flex="calc(50% - 2rem)">
          <TextContainer>
            <HeroTitle role="heading" aria-level={1}>
              {title}
            </HeroTitle>
            <HeroSubtitle>{subtitle}</HeroSubtitle>
            <CallToActionButtonA style={{width: "300px"}} href="https://app.citedrive.com/sign-up/">
              Try CiteDrive now
            </CallToActionButtonA>
          </TextContainer>
        </TextWrapper>

      </HeroContainer>
    </Wrapper>
  );
};

export default Hero;
