import * as React from "react";
import styled from "styled-components";
// import Plus from "../assets/plus"

import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled.section`
  height: 100%;
`;

const Container = styled.div`
  padding: 0 1rem;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
`;

const AccordionWrapper = styled(motion.div)`
  transition: 1s;
  :last-child {
    border-bottom: 1px solid #363636;
  }
  /* @media (min-width: 1028px) {
    margin-left: 20%;
  } */
`;

const Card = styled.div`
  border-top: 1px solid #363636;
`;

const AccordionHeader = styled.button`
  font-size: 1rem;
  padding: 1.5rem 0;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  color: #36003f;
  /* color: #363636; */
  @media (min-width: 1028px) {
    font-size: 1.5rem;
  }
`;

const AccordionBullet = styled.div`
  display: flex;
  align-items: center;
`;

type PlusSignType = {
  open: number | null;
  index: number;
};

const PlusSign = styled.div<PlusSignType>`
  width: 32px;
  transition: 0.15s;
  transform-origin: center;
  transform: ${(props) =>
    props.open === props.index ? "rotate(-45deg)" : null};
`;

const AccrodionBody = styled(motion.p)`
  width: 100%;
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: #16001a;
  padding: 0 0 1.5rem 0;
  transition: 1s;
  @media (min-width: 1028px) {
    width: 75ch;
  }
`;

// const Description = styled.p`
//   width: 100%;
//   font-family: acumin-pro, sans-serif;
//   font-weight: 500;
//   font-style: normal;
//   font-size: 1.55rem;
//   text-align: center;
//   line-height: 3rem;
//   margin-bottom: 6rem;
//   color: #16001a;
//   @media (min-width: 1028px) {
//     width: 75ch;
//   }
// `;

const FeaturesSpan = styled.span`
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.4rem;
  color: #16001a;
`;

const Circle = styled.div`
  border-radius: 100vw;
  height: 8px;
  width: 8px;
  background-color: #16001a;
  margin-right: 1rem;
  margin-left: 1rem;
`;

interface FeaturesProps {
  accordionData: any;
}

interface AccordionProps {
  accordionData: any;
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
  accordionData,
}) => {
  const [open, setOpen] = React.useState<number | null>();

  const toggle = (index: any) => {
    if (open === index) {
      return setOpen(null);
    } else {
      setOpen(index);
    }
  };

  return (
    <AccordionWrapper>
      <FeaturesSpan style={{ marginBottom: "2rem", display: "block" }}>
        Features:
      </FeaturesSpan>
      {accordionData.map((ele, index) =>
        ele.items.map((ele, index) => (
          <Card>
            <AccordionHeader onClick={() => toggle(index)} key={index}>
              <AccordionBullet>
                <Circle />
                {ele.feature_name.text}{" "}
              </AccordionBullet>
              <PlusSign index={index} open={open}>
                {/* <Plus /> */}+
              </PlusSign>
            </AccordionHeader>
            <AnimatePresence>
            {open === index ? (
              <AccrodionBody initial={{opacity: 0, y: -10}} animate={{opacity: 1, y: 0}}>{ele.feature_text.text}</AccrodionBody>
            ) : null}
            </AnimatePresence>
          </Card>
        ))
      )}
    </AccordionWrapper>
  );
};

const Features: React.FunctionComponent<FeaturesProps> = ({
  accordionData,
}) => {
  return (
    <Wrapper>
      <Container>
        <Accordion accordionData={accordionData} />
      </Container>
    </Wrapper>
  );
};

export default Features;
